.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 17vw;
  background: #0a9aff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 9999;
}

@media (max-width: 768px) {
  .side-drawer {
    width: 100vw;
    padding: 0;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgb(10, 154, 255);
    background: linear-gradient(
      180deg,
      rgba(10, 154, 255, 1) 0%,
      rgba(50, 127, 240, 1) 100%
    );
  }
  .side-drawer__logo {
    display: none;
  }
}
