.history-item__container {
  width: auto;
  margin: 1rem 0;
  padding: 0.5rem;
  display: flex;
  border-bottom: 1.5px solid #999;
  justify-content: space-between;
}

.history-item__icon {
  padding: 0 0.5rem;
}

.history-item__button {
  background-color: #0a9aff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 38px;
  height: 38px;
}

.history-item__add {
  font-weight: 300;
  text-align: left;
  flex-basis: 85%;
  margin: 0 auto;
  display: flex;
}

.history-item__info {
  font-weight: 300;
  text-align: left;
  flex-basis: 87%;
  flex-direction: column;
  margin-left: 0;
}

.history-item__info-highlight {
  color: red;
  margin-left: 0.5rem;
}

.history-item__info label {
  padding: 0;
}

.history-item__info .company {
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  font-family: Quicksand;
  margin: 0;
}

.history-item__info p {
  font-size: 0.8rem;
  color: #999;
}

.history-item__icon {
  margin: 0;
}

.work-history-item__invalid {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: -30px;
}