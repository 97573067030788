@import "react-datetime/css/react-datetime.css";

body {
  margin: 0;
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f2240;
  font-size: 16px;
}

@font-face {
  font-family: Recoleta;
  src: url("./resources/fonts/Recoleta-Medium.ttf");
}

@font-face {
  font-family: Quicksand;
  src: url("./resources/fonts/Quicksand-VariableFont_wght.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: Recoleta;
  margin-bottom: 0;
}

h3 {
  font-size: 1rem;
  font-family: Quicksand;
  font-weight: 300;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.no-border {
  border: none !important;
}

.anchorButton {
  background-color: #0A9AFF;
  font-size: 0.8rem;
  width: 140px;
  font-weight: bold;
  padding: 0.5rem 2rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 0 0.3rem;
  border-radius: 6px;
}

body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body button,
body label,
body span,
.helper-text {
  word-break: break-word !important;
  white-space: break-spaces !important;
}

@media (max-width: 768px) {
    .anchorButton {
      width: 90%;
    }
}