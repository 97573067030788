.signup-flow__container {
  min-width: 50vw;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.signup-content__container {
  max-width: 45vw;
  min-width: 40vw;
  margin: 1rem auto auto !important;
  padding: 0;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  font-family: Quicksand;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.signup-header__chevron {
  text-align: left;
  display: flex;
  margin: 1rem 1rem;
  width: 90%;
}

.signup-header__close {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 1rem;
  width: 85%;
}

.signup-header__close > svg {
  margin: 0 auto;
  height: 53px;
}

.signup-header__close span svg {
  height: 1.5rem;
}

.signup-header__chevron span {
  cursor: pointer;
}

.signup-content__container h1 {
  margin-top: 0;
  font-size: 2.5rem;
  text-align: center;
  /* margin: 0 auto; */
}

.signup-content__container h3 {
  margin: 1rem 0.5rem;
}

.signup-form__container {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  word-break: break-all;
}

.progress-bar {
  display: flex;
  height: 20px;
  width: 100%;
  background-color: #eee;
  padding: 0;
  margin: 0;
}

.progress-bar__progress {
  background-color: #1f2240;
  height: 20px;
}

.terms-container {
  display: flex;
  flex-wrap: wrap;
}

.terms-container a,
.terms-container span {
  margin-right: 5px;
}

.terms-container a:last-child {
  margin-left: 0;
}

@media (max-width: 840px) {
  .signup-form__container {
    max-width: 100vw;
  }

  .signup-flow__container {
    width: 100vw;
  }

  .signup-content__container h1 {
    font-size: 2rem;
    margin: 0 0.6rem 2rem 0.6rem;
  }

  .signup-content__container {
    width: 100%;
    max-width: 100%;
    min-height: calc(100vh - 3.5rem);
    padding: 0;
    margin:0 !important;
    justify-content: flex-start;
  }

  .signup-header__close > svg {
    margin-top: 2rem;
    height: 30px;
  }

  .signup-header__close span svg {
    height: 1rem;
  }
}
