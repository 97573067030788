.basic-data__container {
  width: 100%; 
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-data__prompt-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-data__prompt-data {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
}

.basic-data__container .signup-content__container {
  margin-bottom: 2rem;
}

.form-control.select label {
  min-height: 1.8rem;
}
