.work-form__select-container {
    margin:0 0 1.5rem 0;
}

.work-form__select-container label {
    margin-bottom: 0.5rem;
    font-weight: 300;
}

.work-form__select-group {
    display: flex;
    justify-content: space-between;
}

.work-form__select-container span {
    color: red;
    margin-left: 0.4rem;
}

.promp-content__history {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    height: 200px;
}

.css-b62m3t-container, 
.css-3iigni-container {
    min-width: 47%;
    /* margin: 0 0.5rem 0 0; */
}

.css-b62m3t-container:last-child,
.css-3iigni-container:last-child {
    margin: 0;
}