.dashboard-container {
  min-height: calc(100vh - 3.5rem);
  width: 83vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red */
}

.overlay_container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.section-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: Quicksand;
  background-color: white;
}

.section__container:last-child {
  border: 1px solid red;
  padding-bottom: 1rem;
}

.dashboard-container__header {
  padding-bottom: 8rem;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: #1f2240;
  color: white;
}

.dashboard-container__section {
  width: 80%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 10px rgba(83, 83, 83, 0.1);
}

.dashboard-container__section:last-child {
  margin-bottom: 2rem;
}

.dashboard-container__content .dashboard-container__section:first-child {
  margin-top: -5rem;
}
.dashboard-container .section-container:first-of-type {
  background-color: white;
}

.dashboard-container__header h1 {
  font-size: 2.8rem;
  font-weight: 300;
}

.dashboard-container__section h1 {
  font-size: 2.5rem;
  font-weight: 300;
}

.companies-container__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0 0 1.5rem 0;
}

@media (max-width: 768px) {
  .dashboard-container {
    width: 100vw;
  }

  .section__container {
    width: 100%;
    flex-direction: column;
  }
  
  .dashboard-container__section {
    margin-top: 1rem;
    width: 100%;
  }

  .dashboard-container__content .dashboard-container__section:first-child {
    margin-top: 0;
  }

  .dashboard-container h1 {
    font-size: 1.5rem;
  }
  .dashboard-container__header {
    padding: 1rem 0.625rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
  }
  .companies-container__content {
    width: 95%;
    flex-direction: column;
  }
}
