.error-page__container {
    width: 83vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    font-family:Quicksand;
}

.error-page__info {
    display: flex;
    width: 45%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.error-page__info h1 {
    font-size: 54px;
    line-height: 68px;
}

.error-page__info p {
    font-size: 16px;
    line-height: 27px;
    font-weight: 300;
}

.error-page__image {
    display: flex;
    width: 55%;
}

.error-page__image img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

@media (max-width:768px) {
    .error-page__container {
        width: 100vw;
        flex-direction: column;
    }

    .error-page__info,
    .error-page__image {
        width: calc(100vw - 4rem);
    }

    .error-page__info {
        text-align: center;
    }

    .error-page__info h1 {
        font-size: 36px;
    }
}