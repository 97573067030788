.file-upload__container {
  margin: 1rem;
}

.file-upload .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload__image-description {
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
}

.file-upload span {
  font-size: 1rem;
  font-weight: 300;
  color: #1f2240;
}

.file-upload__image-container span {
  margin-top: 1rem;
}

.file-upload__file-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 210px; */
}

.file-upload__file-container__display {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.file-upload__file-container__display span {
  margin-right: 1rem;
}

.file-upload__preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  margin: 1rem 0;
  text-align: center;
  width: 95%;
  border: 1px dashed #979797;
}

.file-upload__preview span {
  color: #1F2240;
  font-size: 14px;
  line-height: 18px;
  font-family: Quicksand;
  margin: 0 1rem;
  width: 90%;
  padding-left: 10%;
}
.file-upload__preview span.uploaded {
  color: #008321;
  font-weight: 600;
}

.form-container__notification {
  background-color: rgb(50, 124, 255, 0.1);
  height: 74px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.3rem 0;
}

.form-container__notification p {
    width: 90%;
    color: #327CFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
    padding: 0;
}

.component_icon {
  margin: 0 0.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .file-upload {
    flex-direction: column;
  }

  .file-upload span {
    margin-top: 0.5rem;
  }
}
