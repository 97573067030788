.signup-form__container h3 {
  margin-bottom: 5rem;
  line-height: 1.6875em;
}

.signup-form__container h1 {
  margin-top: 0;
  font-size: 2.5rem;
}

.resume-inputs__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 45%;
}

@media (max-width: 768px) {
  .signup-form__container h1 {
    font-size: 2.2rem;
    margin-top: 1rem;
  }

  .resume-inputs__container {
    width: 80%;
  }
}
