//position absolute mixin
@mixin position-absolute ($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

//transitions mixin
@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

.checkbox-control {
  max-width: 100%;
  display: inline-flex;
  grid-template-columns: 1em auto;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-family: Quicksand;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
}

.checkbox-control a {
    text-decoration: underline;
    white-space: nowrap;
}

.checkbox-effect:not(:checked),
.checkbox-effect:checked{
  position: absolute;
  left: -9999px;
}

label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.checkbox-effect:not(:checked) + label:before,
.checkbox-effect:checked + label:before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    border: 1.5px solid #1F2240;
    box-sizing: border-box;
    margin-right: 20px;
    @include transition-mix($delay: 0.2s);
}

.checkbox-effect:not(:checked) + label:after,
.checkbox-effect:checked + label:after {
  content: url('../../../resources/images/check.png');
  display: flex;
  width: 16px;
  height: 16px;
  align-items:center;
  @include position-absolute($left: 4px, $top: 6px);
  color: #1F2240;
  @include transition-mix;
  transform-origin: center center;
  opacity: 0;
}

.checkbox-effect:not(:checked) + label:after {
  transform: scale(0);
}

.checkbox-effect:checked + label:before {
  transform: scale(0);
  opacity: 0;
  @include transition-mix;
}

.checkbox-effect:checked + label:after {
  transform: scale(1);
  opacity: 1;
  @include transition-mix($delay: 0.2s, $timing: cubic-bezier(0.57, 1.9, 0, 1.52));
}