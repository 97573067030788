.main-trait__container {
    height: auto !important;
}

.trait-container {
    width: 60%;
    margin: 0 auto;
    height: 100%;
    align-self: center;
}

.signup-content__container h1{
    margin-top: 1rem;
    font-size: 2.5rem;
}

@media (max-width:768px) {
    .trait-container {
        width: 100%;
        height: 100%;
    }
}