.notification {
    z-index: 10000;
    position: fixed;
    width: 100vw;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    color: #1F2240;
  }

  .logged {
    top: 0;
  }

  .signup {
    top: 3.5rem;
  }
  
  .notification__header {
    width: 100%;
    padding: 1rem 0.5rem;
    color: white;
  }
  
  .notification__header h2 {
    margin: 0.5rem;
  }
  
  .notification__content {
    display: flex;
    padding: 1rem 0.5rem;
  }
  
  .notification__content h1 {
    font-size: 2.5rem;
  }
  
  .notification__content h4{
    font-size: 14px;
    font-weight: 300;
  }
  
  .notification__footer {
    padding: 1rem 0.5rem;
    background: white;
  }
  
  @media (max-width: 768px) {
    .notification h1 {
       font-size:1.2rem;
    }
  }
  
  .notification-enter {
    transform: translateY(-10rem);
    opacity: 0;
  }
  
  .notification-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .notification-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .notification-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
  }
  