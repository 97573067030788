.form-container {
  width: 60%;
  box-sizing: border-box;
  margin: 3rem auto 2rem;
  font-weight: 300;
}

.form-control {
  margin: 1rem 0;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
}

.form-control--invalid p,
.form-control label,
.form-control input,
.form-control textarea {
  display: block;
  min-height: 2.3rem;
  font-family: Quicksand;
  font-weight: 300;
  margin-top: -0.5rem;
  align-items: center;
}

.form-control label {
  color: #1F2240;
  font-weight: 300;
  margin-bottom: 0;
}

.form-control span {
  color: red;
  margin-left: 0.2rem;
}

.form-control input,
.form-control textarea {
  font-size: 1rem;
  width: 97%;
  border: 1px solid #AFAFAF;
  border-radius: 6px;
  background: white;
  padding: 0.1rem 0.35rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control textarea {
  resize: none;
}

.form-control--invalid p {
  display: inline;
  color: red;
  font-size: 0.8rem;
  padding:0;
  margin-top: 0.2rem;
  margin-bottom: -0.5rem;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: white;
}

.form-control__validation-log__container {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.form-control__validation-log {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.5rem 0;
}

.form-control__validation-log span {
  font-weight: 500;
  margin: 0.5rem 0;
  font-size: 12px;
  color: red;
}

.form-control__validation-log span.valid {
  color: green;
}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
  }
}

.description {
  font-size: 0.8rem;
  color: #777;
  margin: -12px 0 12px;
}