.interview-container,
.no-interview-container {
  width: 83vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.interview-container {
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
}

.interview-container h1 {
  margin-bottom: 1rem;
}

.no-interview-container__content {
  margin: 2rem 0 !important;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-interview-container__content .logo {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(10, 154, 255, 1) 0%,
    rgba(50, 127, 240, 1) 100%
  );
}

.interview-container__company-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .interview-container__company-container {
    width:100%;
    margin: 1rem;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .interview-container,
  .no-interview-container {
      width: 100vw;
      min-height: calc(100vh - 5.5rem);
      align-items: center;
  }
}
