.blog-container__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 1rem 1.5rem;
}

.section-container button {
    margin-bottom: 2rem;
}

@media (max-width:768px) {
    .blog-container__content {
        flex-direction: column;
        margin-top: 1rem;
    }
}