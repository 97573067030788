.progress-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin: 2rem 0;
    font-family: Quicksand;
    background-color: white;
}
.progress-container .middle-line {
  width: 75%;
  top: 16.5%;
  left:10%;
  margin: 0 auto;
  position: absolute;
  height: 2px;
  transform: translateY(-50%);
  background: #999;
}

.progress-bar__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 25%;
  padding: 1rem;
  line-height: 25px;
}

.progress-bar__text {
  flex-direction: column;
  text-align: center;
}

.progress-bar__item label {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1rem;
  justify-content: center;
}

.progress-bar__item span {
  margin-top: 0.2rem;
  font-weight: 300;
  font-size: 0.9rem;
}

.progress-bar__item-circle__container {
  display: flex;
}

.circle-container {
    width: 80px;
    height: 40px;
    min-width: 80px;
    min-height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}

.progress-bar__item-circle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #999;
  background-color: white;
  z-index: 999;
}

.progress-bar__item-circle img {
  width: 70%;
  height: 70%;
}

.item-circle__active {
    background-color: #4DCC64;
    border: 3px solid #4DCC64;
    transition: all 0.1s linear;
}

@media (max-width: 768px) {
  .progress-container {
    flex-direction: column;
  }

  .progress-container .middle-line {
    width: 2px;
    height: 70%;
    top: 50%;
    left: 12.5%;
}

  .progress-bar__item {
    max-width: 100%;
    flex-direction: row;
  }

  .progress-bar__text {
    flex-direction: column;
    padding-left: 1rem;
    text-align: left;
  }

  .progress-bar__item label {
    justify-content: flex-start;
  }


  .circle-container {
      height: 80px;
      min-height: 80px;
      width: 60px;
      min-width: 60px;
  }
}
