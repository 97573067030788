.nav-links {
  list-style: none;
  font-weight: 300;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  width: 17vw;
  height: 50%;
  display: flex;
  flex-direction: column;
}

.nav-links li {
  display: flex;
  align-items: center;
  width: 91%;
  height: 100%;
}

.nav-links li > a > img {
  width: 18px;
  height: 16px;
  margin-right: 1rem;
}
.nav-links a {
  border: none;
  color: white;
  font-size: 18px;
  text-decoration: none;
  font-variant: small-caps;
  padding: 0.5rem;
  display:flex;
  align-items: center;
  width: 100%;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: white;
  border-left: 5px solid white;
  background-color: rgba(255,255,255,0.1);
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    width: 100%;
  }
  
  .nav-links li:first-child {
    margin-top: 5rem;
  }
  
  .nav-links li {
    margin: 0rem;
    text-align: center;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
    font-size: 22px;
    line-height: 29px;
    font-family: Quicksand;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links li > a > img {
    display: none;
  }
}
