.modal {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: #0A9AFF;
  border-radius: 8px;
  z-index: 10000000;
  position: fixed;
  color: white;
  width: 80%;
  left: 10%;
  top: 22vh;
}

.fullscreen {
  justify-content: center;
  background: #0A9AFF;
  align-content: center;
  align-items: center;
  z-index: 1000000;
  position: fixed;
  color: white;
  height: 100vh;
  width: 100vw;
  left: 0;
}

.fullscreen .modal__content {
  height: calc(50vh);
}

.modal__header {
  padding: 1rem 0.5rem;
  color: white;
  width: 100%;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
  display: flex;
}

.modal__content h1 {
  font-size: 3rem;
}

.modal__content h4{
  font-weight: 300;
  font-size: 14px;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
