.applied-page__container {
  width: 100%;
  max-width: 100vw;
  text-align: center;
}

.applied-page__container .card {
  width: 80%;
  margin: 1rem auto;
  padding: 2.5rem;
}

.applied-page__container .card img {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
}

.applied-page__container .card h1 {
  margin: 0 0 0.625rem;
  font-size: 2.75rem;
  line-height: 3.75rem;
  text-transform: capitalize;
}

.applied-page__container .card p {
  font-size: 0.85rem;
  margin: 0 0 1.85rem;
}

@media (max-width: 768px) {
  .applied-page__container {
    padding: 0;
  }

  .applied-page__container .card {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem;
    margin: 0;
  }

  .applied-page__container .card h1 {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}