.toast-notification__container {
  min-height: 74px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.3rem 0;
}

.toast-notification__container-info {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.toast-notification__container.Error {
  background-color: rgba(255, 50, 50, 0.1);
  color: #8b1414;
}

.toast-notification__container.Success {
  background-color: rgb(60, 165, 79, 0.1);
  color: #3ca54f;
}

.toast-notification__container.Warning {
  background-color: rgba(255, 183, 50, 0.1);
  color: #a88d13;
}
.toast-notification__container-info h2 {
  font-size: 1rem;
}
.toast-notification__container-info p {
  width: 90%;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  padding: 0;
}
