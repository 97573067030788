.form-control__title {
  display: flex;
  align-items: center;
}

.form-control__title > img {
  height: 20px;
  width: 20px;
  margin-top: -0.9rem;
  margin-left: 0.2rem;
}

.tooltip {
  font-weight: bold !important;
}