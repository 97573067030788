.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  margin: 0.2rem;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-navigation__drawer-nav {
  height: 100%;
  display: flex;
}

.main-navigation__drawer-nav ul {
  align-self: flex-start;
  flex-direction: column;
  height: auto;
}

.main-navigation__drawer-nav ul > li {
  align-self: flex-start;
  font-size: 1.1rem;
  padding:0;
  font-weight: 200;
  width: 100%;
}

.main-navigation__drawer-nav ul > li > a {
  box-sizing: border-box;
  font-size: 1.125rem;
  padding: 0.25rem 1rem;
  border-left: 5px solid transparent;
  margin-bottom: 1rem;
}

.main-navigation__header-nav a {
  font-variant: small-caps;
}

.main-navigation__header-nav .nav-links li {
  width: 100%;
}

.main-navigation__header-nav .nav-links a {
  box-sizing: border-box;
  justify-content: center;
}

.main-navigation__header-nav .nav-links a:hover {
  border-left: none;
  background-color: transparent;
}

.main-navigation__logo {
  display: block;
  margin-left:2rem;
}

.side-drawer__logo {
  width: 60%;
  display: block;
  margin:1rem 2rem;
}

@media (max-width: 768px) {
  .main-navigation__header-nav {
    display: block;
  }

  .main-navigation__drawer-nav {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .main-navigation__header-nav {
    margin-right: 0.5rem;
  }

  .main-navigation__header-nav .nav-links {
    height: 100%;
  }

  .main-navigation__header-nav .nav-links li:first-child {
    margin-top: 0;
  }

  .main-navigation__header-nav .nav-links li a {
    font-size: 18px;
    font-weight: bold;
  }
}