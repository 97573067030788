.list-container {
    padding: 0;
    width: 100%;
  }
  
  .trait-list {
    display: flex;
    padding: 0;
    margin: 1em auto;
    width: 100%;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .trait-list li {
    text-align: left;
    margin: 0.4rem;
    padding: 0;
  }
  
  .trait-list button {
    padding: 0.3rem 0.625rem;
    font-size: 0.88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border:none;
    background-color: transparent;
    font-family: Quicksand;
    line-height: 1.15rem;
  }

  .trait-item {
    display: flex;
    background-color: #EEE;
    border-radius: 1rem;
    padding: 0;
    margin: 0;
}

.trait-item__selected {
    background-color: #0A9AFF;
}

.trait-list__selected button {
    color:white;
  }
  
  