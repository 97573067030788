.settings__container {
  width: 100%;
  margin: 0 1rem;
}

.settings__section .card {
  max-width: 420px;
  margin: 2rem auto 0;
}

.settings__section h1 {
  text-align: center;
  align-self: center;
}

.settings__section button {
  display: block;
  margin: auto;
}

.settings__section .form-container {
  width: 70%;
}

.settings__support-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.support {
  color: #327CFF;
}

@media (max-width: 768px ) {
  .settings__container h1 {
    text-align: center;
  }
  .settings__section .card {
    border-radius: 6px;
  }

  .settings__section .form-container {
    width: 90%;
  }
}
