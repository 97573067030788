.video-thumbnail__container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-thumbnail__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: linear-gradient(0deg, rgb(29, 66, 2) 0%, rgba(78,121,9,1) 25%, rgba(78,189,212,1) 64%, rgba(188,230,238,1) 100%);
}

.video-thumbnail__play-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.video-thumbnail__container .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -40%);
  
}

.video-thumbnail__container .spinner .lds-dual-ring::after {
  border-color: white transparent;
}
