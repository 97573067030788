.location-form__select {
    font-weight: 300;
    
}

.location-form__select label {
    font-weight: 300;
    margin: 0.5rem 0;
}

.chkContainer {
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    padding: 0.5rem 0;
}

.location-form__remote {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-container__footer {
    display: flex;
    justify-content: center;
    
}