.button {
  font: inherit;
  min-height: 52px;
  font-weight: bold;
  padding: 1rem 2rem;
  border: 1px solid #0A9AFF;
  border-radius: 6px;
  background: #0A9AFF;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 0 0.3rem;
}

.button img {
  margin: 0 0.5rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #0A9AFF;
  border-color: #0A9AFF;
}

.button--outline {
  border: 1px solid #327CFF;
  background-color: transparent;
  color: #1F2240;
}

.button--outline:hover {
  background-color: #EEE;
}

.button--inverse {
  background: #1F2240;
  border: none;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #131427;
}

.button--danger {
  background: #fe5655;
  border-color: #fe5655;
  color: #fff;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
  min-width: 180px;
}

.button--big {
  font-size: 0.9rem;
  min-width:140px;
  max-width: 450px;
  height:52px;
}

.button--whole {
  width:100%;
  margin: 0;
}

.button--centred {
  margin: 0 auto;
}

@media (max-width:768px) {
  .button {
    font-weight: bold;
  }

  .button--big {
    font-size: 0.8rem;
    width:90%;
  }
}