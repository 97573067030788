.education-summary__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  font-weight: 300;
  margin-bottom: 1rem;
}
.education-summary__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  text-align: left;
}

.education-summary__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}

.education-summary__info-highlight {
  color: red;
  font-weight: bold;
  line-height: 27px;
  font-size: 16px;
}

.education-summary__headings {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.education-summary__info h3 {
  line-height: 27px;
  font-size: 16px;
  font-weight: bold;
  width: 70%;
}

.education-summary__info ul {
  list-style: none;
  margin-block-end: auto;
  margin-block-start: auto;
  margin-inline: -2rem;
}

.education-summary__info ul > li {
  line-height: 27px;
}

.education-summary__info-date {
  color: #999;
}

.education-summary__info ul > li > ul {
  list-style: disc;
}

.education-summary__dismiss,
.education-summary__edit {
  align-self: flex-start;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.education-summary__footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.education-summary__invalid {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}