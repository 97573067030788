.video-edit__container {
  padding: 2.5rem !important;
  width: 100%;
  max-width: 640px;
  margin: 1rem auto !important;
  box-sizing: border-box;
  border-radius: 0;
  height: max-content;
}

.video-edit__container h1 {
  text-align: center;
  font-size: 2.75rem;
  line-height: 3.75rem;
  margin-top: 2.5rem;
  font-weight: normal;
}

.video-edit__container .header__close {
  width: 100%;
  display: flex;
}

.video-edit__container .header__close > svg {
  height: 53px;
  margin: auto;
}

.video-edit__container .header__close span svg {
  height: 24px;
  margin: auto;
}

.videos-list__container {
  width: 70%;
  margin: 1rem auto 0;
}

.videos-list__item {
  width: 100%;
  border-bottom: 1px solid #000;
  padding: 1.25rem 0;
  display: flex;
}

.videos-list__video-container {
  width: 152px;
  height: 152px;
  border-radius: 0.25rem;
  flex-shrink: 0;
  overflow: hidden;
}

.videos-list__video-container .placeholder {
  background-color: #327cff;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 1.5rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.videos-list__prompt {
  margin-left: 1.5rem;
  font-size: 0.875rem;
  position: relative;
  flex-grow: 1;
  padding-top: 1rem;
}

.videos-list__prompt span {
  font-weight: bold;
}

.videos-list__prompt .actions {
  position: absolute;
  bottom: 0;
}

.videos-list__prompt .actions button {
  background-color: transparent;
  border: none;
  font-family: Quicksand;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  cursor: pointer;
  padding: 0;
  margin: 0.375rem 0 0.25rem;
}

.videos-list__prompt .actions .replace {
  color: #327cff;
}

.videos-list__prompt .actions .replace::before {
  content: url('../../../resources/graphics/film-b.svg');
  margin-right: 0.625rem;
}

.videos-list__prompt .actions .delete {
  color: #999;
}

.videos-list__prompt .actions .delete::before {
  content: url('../../../resources/graphics/trash.svg');
  margin-right: 0.625rem;
}

.toast-notification__container {
  width: 70%;
  padding: 1rem;
  min-height: unset;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .video-edit__container {
    padding: 1rem;
  }

  .videos-list__container,
  .toast-notification__container {
    width: 100%;
  }

  .video-edit__container .header__close > svg {
    height: 1.875rem;
    margin: auto !important;
  }
  
  .video-edit__container .header__close span svg {
    height: 1rem;
    margin: auto !important;
  }  
}
