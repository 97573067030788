.profile__basic-info__personal,
.profile__basic-info__category {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1.2rem 0 1.2rem;
}

.profile__basic-info__category:last-child {
  margin-bottom: 2rem;
}

.profile__basic-info__category-info {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.profile__basic-info__category-icon-container {
  width: 24px;
  height: 24px;
  background-color: #0a9aff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__basic-info__category-info .update-info {
  font-size: 11px;
  line-height: 27px;
}

.profile__basic-info__category-info .placeholder {
  color: #bdbdbd;
  font-weight: 200;
}

.profile__basic-info__category h2 {
  font-weight: bold;
  font-size: 23px;
  line-height: 36px;
  margin: 0.3rem 0;
}

.profile__basic-info__personal__user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile__basic-info__category-info span,
.profile__basic-info__personal__user span {
  max-width: 90%;
  font-size: 16px;
  line-height: 27px;
  font-family: Quicksand;
  font-weight: 300;
  margin: 0 1rem;
}

.profile__basic-info__category-info span {
  margin: 0;
}

.profile__basic-info__personal__info {
  display: flex;
  flex-direction: column;
}

@media (max-width: 840px) {
  .profile__basic-info:first-child {
    margin-top: 0 !important;
  }

  .profile__basic-info {
    width: 100% !important;
    box-sizing: border-box !important;
    margin: 0 0 1rem !important;
  }

  .profile__basic-info,
  .profile__basic-info__personal__user {
    width: calc(100% - 23px);
  }

  .profile__basic-info__personal__user span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 0 0 1rem;
  }

  .profile__basic-info .avatar {
    width: 77px !important;
    height: 77px !important;
    margin: 0;
  }

  .profile__basic-info__personal__info {
    flex-grow: 1;
    max-width: calc(100% - 77px);
  }

  .profile__basic-info h1 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin: 0 0 1.25rem;
  }

  .profile__basic-info__personal,
  .profile__basic-info__category {
    margin: 1rem;
  }

  .profile__basic-info__category h2 {
    font-size: 1rem;
    line-height: 1.68rem;
  }
}
