.blog-article {
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    margin: 0 1rem;
    font-family: Quicksand;
    text-decoration: none;
}

.blog-article:visited {
    color: inherit;
}

.blog-article .img-container {
    width: 100%;
    padding-bottom: 55%;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
}

.blog-article label {
    font-size: 23px;
    line-height: 36px;
}

.blog-article p {
    line-height: 27px;
    font-size: 16px;
    text-align: left;
}

@media (max-width: 768px) {
    .blog-article {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
}