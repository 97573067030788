.main-header__loggedin,
.main-header {
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #0A9AFF;
  /* padding: 1 1rem; */
  z-index: 100;
}

.main-header__loggedin {
  display: none;
}

.main_logged_out {
  margin-top: 3.5rem;
  background-color: #EEEEEE;
  min-height: calc(100vh - 3.5rem);
  display: flex;
}

.main_logged_in {
  margin-left: 17vw;
  background-color: #EEEEEE;
  min-height: 100vh;
  display: flex;
}

@media (max-width: 768px) {
  .main-header {
    justify-content: space-between;
  }

  .main_logged_in {
    margin-left: 0;
    margin-top: 3.5rem;
    min-height: 90vh;
  }

  .main-header__loggedin {
    display: flex;
    z-index: 99999;
  }
}