.login-container {
    height: calc(100vh - 3.5rem);
    width: 100vw;
    display: flex;
    /* border:1px solid red; */
}

.login-container__image {
    background-image: url('../../resources/images/login/login_img@3x.png');
    width: 50vw;
    background-size: cover;
    background-position: top center;
}

.login-container__image-lowres {
    background-image: url('../../resources/images/login/login_img.png');
    left: 0;
    width: 50vw;
    background-size: 50vw 100vh;
    background-repeat: no-repeat;
}

.login-container__form-container {
    right: 0;
    width: 50vw;
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: center;
    position: fixed;
    height: inherit;
    overflow: scroll;
}

.link-separator {
    margin: 1rem 0;
}

.link-separator span {
    text-decoration: none;
    color: #1F2240;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
}

.link {
    color: #327CFF;
    font-size: 17px;
    margin: 1rem 0;
    cursor: pointer;
}

.form-container__subtle-text {
    font-size: 12px;
    color: #535353;
}

.sign-in {
    margin: 1rem 0;
  }

.disabled-link {
    color: #efefef;
}

@media (max-width:768px) {
    .login-container__form-container {
        width: 100vw;
    }
}