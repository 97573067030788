.company__holder {
  padding: 0;
  margin: 1rem !important;
  display: flex;
  min-width: 30%;
  flex-direction: row;
}

.company__holder:hover {
  cursor: pointer;
}

.company-container {
  padding: 0;
  flex-grow: 1;
  display: flex;
  min-width: 90%;
  min-height: 140px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}

.company-container__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 1rem;
}

.company-container__header span {
  margin: 0 1rem;
}

.company-container__description {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin: 1rem;
}

.company-container__description p {
  margin: 0;
  line-height: 18px;
  font-size: 14px;
}

.company-container__description p.validation {
  margin: 1rem 0;
  font-weight: 700;
}

.company-container__description .validation.Interested,
.company-container__description .validation.Match,
.company-container__description .validation.Offer {
  color: #3ca54f;
  margin-bottom: 0;
  display: none;
}

.company-container .company-container__placeholder {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #1f2240;
  text-align: center;
  line-height: 45px;
  font-weight: bold;
  font-size: 1.5em;
  color: #fff;
  font-family: Recoleta;
}

.company-container__footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #999;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.company-container__footer-button {
  width: 100%;
  padding: 0.5rem;
}

.company-container__footer-option {
  text-align: center;
  flex-basis: 50%;
  padding: 0.5rem 1rem;
  font-variant: small-caps;
  font-size: 1.5rem;
  border-right: 1px solid #999;
  cursor: pointer;
}

.company-container__footer-option:last-child {
  border: none;
}

.company-enter {
  opacity: 0;
}

.company-enter-active {
  opacity: 1;
  transition: opacvity 500ms;
}

.company-exit {
  opacity: 1;
}

.company-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.company_holder__right_interaction {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
}

@media (max-width: 768px) {
  .company__holder {
    margin: 1rem 0;
    width: 85%;
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26) !important;
  }
  
  .company-container {
    width: 100%;
    max-width: 100%;
    justify-content: flex;
    align-items: center;
    margin: 1rem 0;
  }

  .company-container__header {
    justify-content: flex-start;
    align-self: flex-start;
  }

  .company-container__description {
    align-self: flex-start;
  }
}
