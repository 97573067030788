.not-found__container.loggedout {
    height: calc(100vh - 3.5rem);
    width: 100vw;
    display: flex;
    justify-content: center;
}

.not-found__container.loggedin {
    height: 100vh;
    width: 83vw;
    display: flex;
    justify-content: center;
}

.not-found__container__image {
    background-image: url('../../resources/images/404/404_image@3x.png');
    width: 100%;
    background-size: cover;
    background-position: center;
}

.not-found__container__image.loggedout {
    width: 100vw;
    background-size: 100vw 60vh;
}

.not-found__container__image.loggedin {
    width: 83vw;
    background-size: 83vw 60vh;
}
 
.not-found__container__image-lowres {
    background-image: url('../../resources/images/404/404_image.png');
    left: 0;
    height: 60vh;
    max-height: 60vh;
    background-repeat: no-repeat;
}

.not-found__container__image-lowres.loggedout {
    width: 100vw;
    background-size: 100vw 60vh;
}

.not-found__container__image-lowres.loggedin {
    width: 83vw;
    background-size: 83vw 60vh;
}

.not-found__container__form-container {
    bottom: 0;
    width: 100%;
    height: 40vh;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    text-align: center;
    position: fixed;
}

.not-found__container__form-container h1 {
    font-size: 2.5rem;
}

.not-found__container__form-container h2 {
    font-family: Recoleta;
    font-size: 1.2rem;
}

.not-found__container__content {
    width: 90%;
    margin: 0 auto;
}

.not-found__form {
    width: 70%;
    margin: 0 auto;
}