.overlay {
  cursor: wait;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.25);
  content: "";
  top: 0;
  left: 0;
  z-index: 9999;
}

.overlay .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
