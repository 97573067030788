.signup-content__container.video-about__container {
  margin: 1rem auto auto !important;
}

.signup-content__container.video-about__container .signup-form__container {
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.video-container {
  margin-bottom: 1rem;
  position:relative;
}

.video-container .spinner-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.spinner {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
